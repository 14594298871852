<template>
	<div :id="divId">
		<textarea name="fortressEditor007" :id="id" rows="10" cols="80"></textarea>
	</div>
</template>

<script>
export default {
	name: "FortressEditor",
	props: ["value", "disabled"],
	data() {
		return {
			id: "fk" + parseInt(Math.random() * 10000),
			divId: "fe" + parseInt(Math.random() * 10000),
			ckEditor: null,
		}
	},
	mounted() {
		const self = this
		//渲染编辑器
		this.ckEditor = CKEDITOR.replace(this.id)
		//设置初始值
		CKEDITOR.instances[this.id].setData(this.value ? this.value : "")
		//监听内容变化
		CKEDITOR.instances[this.id].on("change", function() {
			self.$emit("input", CKEDITOR.instances[self.id].getData())
		})
	},
	methods: {
		setData(value = "") {
			if (CKEDITOR.instances[this.id]) {
				CKEDITOR.instances[this.id].setData(value)
			}
		},
		//销毁编辑器
		destroy() {
			if (CKEDITOR.instances[this.id]) {
				CKEDITOR.instances[this.id].destroy()
			}
		},
	},
	watch: {
		value: {
			handler(val) {
				let self = this
				let ckData = val != null ? val : ""
				if (CKEDITOR.instances[this.id] && ckData != CKEDITOR.instances[self.id].getData()) {
					// CKEDITOR.instances[this.id].setData(ckData);
					let timmer = setInterval(function() {
						if (
							document.getElementById(self.divId).getElementsByTagName("iframe") &&
							document.getElementById(self.divId).getElementsByTagName("iframe").length > 0
						) {
							let a = document.getElementById(self.divId).getElementsByTagName("iframe")[0].contentWindow.document
							if (a.body) {
								a.body.innerHTML = ckData
							}
							clearInterval(timmer)
						}
					}, 100)
				}
			},
			immediate: true,
		},
	},
}
</script>
