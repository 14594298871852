<template>
  <ele-form
    :form-data="formData"
    :form-desc="formDesc"
    :is-show-back-btn="false"
    :is-show-submit-btn="false"
  />
</template>
<script>
export default {
  name: "FortressUpload",
  props: {
    type: String || "image",
    name: String,
    formData: Object || {},
    size: Number,
    multiple: Boolean || false,
	  nowater: Boolean
  },
  data() {
    return {
      formValue: this.name,
      formDesc: {},
    };
  },
  created() {
    console.log('created this.nowater :', this.nowater)
    if (this.type == "file") {
      this.formDesc[this.name] = {
        type: "upload-file",
        attrs: {
          fileSize: this.size ? this.size : 10,
          name: "upfile",
          action: window.$conf.commonConf.baseUrl +  (this.nowater ? '/system/oss/uploadNoWatermark':"/system/oss/upload"),
          responseFn(response, file) {
            return {
              name: file.name,
              url: response.data.url,
            };
          },
        },
      };
    } else if (this.type == "video") {
      this.formDesc[this.name] = {
        type: "video-uploader",
        attrs: {
          fileSize: this.size ? this.size : 20,
          action: window.$conf.commonConf.baseUrl +  (this.nowater ? '/system/oss/uploadNoWatermark':"/system/oss/upload"),
          // 上传的文件字段名 (同官网)
          name: "upfile",
          responseFn(response) {
            return response.data.url;
          },
        },
      };
    } else if (this.type == "image") {
      this.formDesc[this.name] = {
        type: "image-uploader",
        attrs: {
          fileSize: this.size ? this.size : 3,
          action: window.$conf.commonConf.baseUrl +  (this.nowater ? '/system/oss/uploadNoWatermark':"/system/oss/upload"),
          // 上传的文件字段名 (同官网)
          name: "upfile",
          multiple: this.multiple,
          responseFn(response) {
            return response.data.url;
          },
        },
      };
    } else if (this.type == "videoShow") {
      this.formDesc[this.name] = {
        type: "gallery",
        default: this.videoSrc,
        attrs: {
          type: "video",
          size: this.size ? this.size : 100,
        },
      };
    }
  },
};
</script>
